<div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <h2 class="more-vertical">
          Release List <nb-icon nbTooltip="Click here to reload your data" nbTooltipStatus="primary" class="ion-loop ng-star-inserted text-primary"></nb-icon> </h2>
        <input class="w-50 mx-2"  nbTooltip="Fill this field to find department" nbTooltipStatus="primary" [disabled]="releases.length === 0" type="text"  fieldSize="large" nbInput placeholder="search by fields" [(ngModel)]="searchbyfields">
      </div>

    </div>
    <div class="col-md-6 col-sm-8">
      <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/release/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
      &nbsp;
      <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/release/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
      &nbsp;
      <a [routerLink]="['/hr/release/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
      &nbsp;
      <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success"><i class="fas fa-file-excel text-white"></i>  </button>
      &nbsp;



        <br>
  </div>

  <div class="row mt-2">
    <div class="col-md-3">
      <div class="row">
        <div class="col-lg-9">
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select (selectedChange)="filterByEmployee($event)"
             class="w-100 mt-3" selected="0">
              <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; Employee</nb-option>
              <nb-option *ngFor="let employee of employees" value="{{employee.id}}">&nbsp;&nbsp;&nbsp; {{employee.employeeName}}</nb-option>

            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">
          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select selected="0" (selectedChange)="filterByDepartment($event)" class="w-100 mt-3">
              <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; Department</nb-option>
              <nb-option *ngFor="let department of departments" value="{{department.id}}">&nbsp;&nbsp;&nbsp; {{department.departmentName}}</nb-option>
            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">

          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
            <nb-select   selected="0" (selectedChange)="filterByStatus($event)" class="w-100 mt-3">
              <nb-option  value="0" disabled>&nbsp;&nbsp;&nbsp; Status</nb-option>
              <nb-option  value="Pending" >&nbsp;&nbsp;&nbsp; Pending</nb-option>
              <nb-option  value="Validate" >&nbsp;&nbsp;&nbsp; Validate</nb-option>
              <nb-option  value="Rejected" >&nbsp;&nbsp;&nbsp; Rejected</nb-option>
            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">

          <nb-form-field>
            <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
            <nb-select   selected="0" class="w-100 mt-3">
              <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Date</nb-option>
              <nb-option value="" (click)="getReleases()">&nbsp;&nbsp;&nbsp; All</nb-option>
              <nb-option value="" (click)="getReleasesAsc()">&nbsp;&nbsp;&nbsp; Ascending</nb-option>
              <nb-option value="" (click)="getReleasesDesc()">&nbsp;&nbsp;&nbsp; Descending</nb-option>
            </nb-select>
          </nb-form-field>
        </div>
        <div class="col-lg-9">
          <label class="mt-3">Items Per Page</label>
          <input class="w-100" [disabled]="releases?.length === 0" type="number" nbInput [(ngModel)]="pageSize" min="9">
        </div>

    </div>

    </div>
        <div class="col-md-9 mt-3">
          <div class="row w-100" *ngIf="loading">
            <nb-alert [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary" class="w-100 mt-6" style="background-color: transparent !important;"></nb-alert>
          </div>
          <div class="row w-100 mx-1" *ngIf="!loading && (!releases || releases.length === 0)">
            <nb-alert outline="danger" class="w-100 mt-3"> No Data Found.</nb-alert>
        </div>
        <div [hidden]="releases.length === 0" class="row w-100 mx-1" *ngIf="(releases | filter : searchbyfields).length === 0">
            <nb-alert outline="danger" fullWidth class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
        </div>
            <div class="row mx-1">

                <div class="col-md-4 " *ngFor="let release of releases | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}" >
                    <nb-card [hidden]="release.releaseStatus==='Draft'">
                            <nb-card-body>
                                <div class="row">
                                    <div class="col-md-9">
                                        <nb-user size="medium" [name]="release?.employee?.employeeName" [picture]="release?.employee?.employeeProfileImage"></nb-user>
                                        <br>
                                        <span>
                                            <nb-icon icon="home-outline"></nb-icon> {{release?.employee?.subDepartment?.subDepartmentName}}</span>
                                        <br><br>
                                        <span> <nb-icon icon="award-outline"></nb-icon> {{release.motifRelease}}</span>
                                        <br><br>
                                        <span>
                                            <i class="fas fa-clock"></i>  {{release.startTime}} -- {{release.endTime}}</span>
                                            <br><br>
                                        <span>
                                             <nb-icon icon="calendar-outline"></nb-icon> {{release.createdAt | date : 'dd MMM YYYY'}}</span>
                                            <br><br>
                                          <nb-icon icon="toggle-right-outline"></nb-icon> &nbsp;

                                      <nb-tag *ngIf="release.releaseStatus === 'Pending'" status="basic" [text]="release.releaseStatus"></nb-tag>

                                      <nb-tag *ngIf="release.releaseStatus === 'Validate'" status="success" [text]="release.releaseStatus"></nb-tag>

                                      <nb-tag *ngIf="release.releaseStatus === 'Rejected'" status="danger" [text]="release.releaseStatus"></nb-tag>


                                    </div>
                                    <div class="col-md-3">
                                        <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                          <nb-icon icon="more-horizontal-outline"></nb-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                          <a [routerLink]="['/hr/release/edit', release.id]" style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary">
                                            <button mat-menu-item>
                                              <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                            </button>
                                          </a>
                                          <a nbTooltip="Click here to archive data" nbTooltipStatus="primary">
                                            <button mat-menu-item>
                                              <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
                                            </button>
                                          </a>

                                        </mat-menu>
                                    </div>

                                </div>

                            </nb-card-body>
                    </nb-card>
                </div>

            </div>
            <br>
            <div class="" align="center">
              <nb-card class="card col-md-7" style="height: 35px; border-radius: 25px;">
                <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
              </nb-card>
            </div>
        </div>
  </div>
