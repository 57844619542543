import {Component, OnInit} from '@angular/core';
import {
  ApplicationServiceService} from '../../../../services/recruitment/applicationService/application-service.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'ngx-history-documents',
  templateUrl: './history-documents.component.html',
  styleUrls: ['./history-documents.component.scss'],
})
export class HistoryDocumentsComponent implements OnInit {
  searchbyfields: string = '';
  files: any[] = [];
  p: number = 1; // Current page number
  constructor(private fileService: ApplicationServiceService) { }

  ngOnInit(): void {
    this.fileService.getFiles().subscribe(files => {
      this.files = files.map(file => ({
        date: file.split(' - ')[0],
        name: file.split(' - ')[1],
      }));
    });
  }

  downloadFile(filename: string, event: MouseEvent): void {
    event.preventDefault();
    this.fileService.downloadFile(filename).subscribe(data => {
      saveAs(data, filename);
    }, error => {
      console.error('Download failed:', error);
    });
  }


}
