import {Component, OnInit} from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {Application} from '../../../../models/Application';
import {ApplicationServiceService} from '../../../../services/recruitment/applicationService/application-service.service';
import {Router} from '@angular/router';
import {finalize, switchMap, take} from 'rxjs/operators';
import {forkJoin, timer} from 'rxjs';
@Component({
  selector: 'ngx-history-applications',
  templateUrl: './history-applications.component.html',
  styleUrls: ['./history-applications.component.scss'],
})
export class HistoryApplicationsComponent implements OnInit {
  config: NbToastrConfig;
  title = 'Create New Skill';
  content = 'Operation achieved';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  applications: Application[] = [];

  searchbyfields: string = '';

  pageSize = 11;

  current = 1;
  loading: boolean = false;
  constructor(private as: ApplicationServiceService, private toastrService: NbToastrService, private router: Router) { }

  ngOnInit(): void {
    this.loading = true; // Start loading
    forkJoin([
      this.as.findAll(),
    ]).pipe(
      finalize(() => this.loading = false), // Stop loading
    ).subscribe({
      next: ([applications]) => {
        this.applications = [...applications].filter(app => app.archived === false).reverse();
      },
      error: (err) => {
        console.error('Error loading data', err);
        this.showToast('danger', 'ERROR', 'Error while retrieving data');
      },
    });
  }


  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body,
      ` ${titleContent}`,
      config);
  }


  getApplications() {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter(app => app.archived === true);
      // this.showToast('success', 'SUCESS', 'Data Loaded Successfuly');
    }, (err) => {
      console.log(err);
      this.showToast('danger', 'DANGER', 'Error While Retrieving Data');

    });
  }





  getFirstNameSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.firstName === event);
    }, (err) => {
      console.log(err);

    });
  }

  getLastNameSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.lastName === event);
    }, (err) => {
      console.log(err);

    });
  }


  getStatusSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.status === event);
    }, (err) => {
      console.log(err);

    });
  }

  getJobSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.refJob === event);
    }, (err) => {
      console.log(err);

    });
  }
  restoreApplication(id: string) {
    this.as.restoreApplication(id).subscribe({
      next: (res) => {
        this.showToast('success', 'SUCCESS', 'Application Restored Successfully');
        this.router.navigate(['/hr/recruitment/applications/grid']); // Redirect
      },
      error: (err) => {
        console.error(err);
        this.showToast('danger', 'ERROR', 'Failed to Restore Application');
      },
    });
  }

}
