import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbComponentStatus, NbDialogService, NbGlobalPhysicalPosition,
  NbGlobalPosition, NbToastrConfig, NbToastrService, NbWindowService } from '@nebular/theme';
import { ExportService } from '../../../../../shared/exports/export.service';
import { SubDepartment } from '../../../models/SubDepartment';
import { Employee } from '../../../models/Employee';
import { EmployeeService } from '../../../services/employeeServices/employee.service';
import GoogleCountries from '../../../services/googlecountries.json';
import {DocumentService } from '../../../services/document.service';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {Browser} from 'leaflet';
import { EmployeeData } from '../../../models/EmployeeData';
import {forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SubDepartmentService} from '../../../services/departmentservices/subDepartmentService/sub-department.service';
const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'ngx-list-employees',
  templateUrl: './list-employees.component.html',
  styleUrls: ['./list-employees.component.css'],
})
export class ListEmployeesComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  current: number = 1;

  config: NbToastrConfig;
  title = 'Create SubDepartment';
  content = 'Operation achieved, reload your page';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  list: Employee[] = [];
  employees: EmployeeData[] = [];
  listd: SubDepartment[] = [];
  countries: any [] = [];


  pageSize: number = 4;

  NotAssignedYet: string = 'Not Assigned Yet.';

  searchbyfields: string;
  total: string | number;
  loading = false;



  constructor(private exportService: ExportService, private router: Router,
              private es: EmployeeService, private ds: SubDepartmentService,
              private ws: NbWindowService , private dialogService: NbDialogService ,
              private toastrService: NbToastrService,private documentService : DocumentService) { }

  handlePageChange(event) {
    this.current = event;
    console.log(this.current);
}


  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      employees: this.es.findAll().pipe(tap(data => this.list = data)),
      subDepartments: this.ds.findAll().pipe(
        tap(data => this.listd = data),
      ),
    }).subscribe({
      next: result => {
        this.countries = GoogleCountries;
        this.loading = false; // Set loading false only after all data is fetched
      },
      error: err => {
        console.error(err);
        this.loading = false; // Ensure loading is set to false even if there is an error
      },
    });
  }

  sortByNamesAsc() {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = data.sort((a, b) => a['employeeName']
          .localeCompare(b['employeeName'])); }, (err) => {
        console.log(err);
      },
    );
  }

  sortByNamesDesc() {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = data.sort((a, b) => b['employeeName']
          .localeCompare(a['employeeName'])); }, (err) => {
        console.log(err);
      },
    );
  }

  getEmpsDesc() {
    this.es.findAllEmployeesDesc().subscribe(
      (data: Employee[]) => { this.list = data;  }, (err) => {
        console.log(err);
      },
    );
  }
  getEmpsAsc() {
    this.es.findAllEmployeesAsc().subscribe(
      (data: Employee[]) => { this.list = data;  }, (err) => {
        console.log(err);
      },
    );
  }

  getAllEmps() {
    this.es.findAll().subscribe(
      (data: Employee[]) => { this.list = data;  }, (err) => {
        console.log(err);
      },
    );
  }
  filtre(e, x) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = [];
        this.list = data.filter(
          (empl =>
              x === 'Gender' ? empl.employeeGender === e : empl.employeeCountry === e
          ),

        );
      }, (err) => {
        return err;
      });
  }
  filtreRole(e, x) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = [];
        this.list = data.filter(
          (empl =>
             ( (x === 'Role') ? empl.roleEmployee === e : null     )
          ),

        );
      }, (err) => {
        return err;
      });
  }

  filtreSubDepartment(d) {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        this.list = [];
        this.list = data.filter(
          (empl =>
              // @ts-ignore
              ( empl?.subDepartment?.id === d   )
          ),

        );
      }, (err) => {
        return err;
      });
  }

  getEmpsByGender(e, x) {
        this.filtre(e, x);
  }

  getEmpsByRole(e, x) {
    this.filtreRole(e, x);
}

getEmpsBySubDepartment(d) {
  this.filtreSubDepartment(d);
}



  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(
      dialog,
      { context: 'Are you sure to delete this item ?' });
  }

  deleteEmp(employee: Employee) {
    this.es.deleteEmployeeById(employee).subscribe(
      (result) => {
        this.showToast('success', 'SUCESS', 'Deleted Successfuly');
        this.router.navigate(['/hr/employee/list_']).then(() => {
          this.getEmpsAsc();
         });
      }, (err) => {
        this.showToast('danger', 'FAILURE', 'Could not delete Employee');
        console.log(err);
      },
    );
  }

  refresh(): void {
    window.location.reload();
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? `. ${title}` : '';
    this.toastrService.show(
      body,
      `Employee ${titleContent}`,
      config);
  }


  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.list, 'dataEmployees');
    const trace = {
      fileName: 'Employee_List.xlsx',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      module: 'HCM',
      createdAt: new Date().toISOString()
    };

    this.documentService.saveTrace(trace).subscribe({
      next: () => console.log('Trace saved successfully'),
      error: (err) => console.error('Error saving trace', err)
    });
  }

  public downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;
    const html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = {
      pageOrientation: 'landscape',
      pageSize: 'A3',
      content: [html],
     };

  // pdfMake.createPdf(documentDefinition).download();

  }

  updateIsEmployeeArchived(employee: Employee, id: string) {
    this.es.updateIsArchived(employee, id).subscribe(
      (res) => {
        this.showToast('success', 'SUCESS', 'Item Is Archived');
        this.router.navigate(['/hr/employee/list_']).then(() => {
          this.getEmpsAsc();
         });
      }, (err) => {
        this.showToast('danger', 'Danger', err.data);
      },
    );
  }






}
