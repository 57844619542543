import { Component, OnInit } from '@angular/core';
import { NbToastrConfig, NbComponentStatus, NbGlobalPosition, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Application } from '../../../../models/Application';
import { ApplicationServiceService } from '../../../../services/recruitment/applicationService/application-service.service';
import {Router} from '@angular/router';
import {forkJoin, timer} from 'rxjs';
import {finalize, switchMap} from 'rxjs/operators';

@Component({
  selector: 'ngx-table-applications',
  templateUrl: './table-applications.component.html',
  styleUrls: ['./table-applications.component.scss'],
})
export class TableApplicationsComponent implements OnInit {

  config: NbToastrConfig;
  title = 'Create New Skill';
  content = 'Operation achieved';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  applications: Application[] = [];

  searchbyfields: string = '';

  pageSize = 11;

  current = 1;
  selectedFile: File = null;
  loading: boolean = false;
  pollingInterval = 5000;

  constructor(private as: ApplicationServiceService, private toastrService: NbToastrService, private router: Router) { }

  ngOnInit(): void {
    this.loading = true; // Start loading
    forkJoin([
      this.as.findAll(),
    ]).pipe(
      finalize(() => this.loading = false), // Stop loading
    ).subscribe({
      next: ([applications]) => {
        this.applications = [...applications].filter(app => app.archived === false).reverse();
      },
      error: (err) => {
        console.error('Error loading data', err);
        this.showToast('danger', 'ERROR', 'Error while retrieving data');
      },
    });
  }


  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body,
      ` ${titleContent}`,
      config);
  }


  getApplications() {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter(app => app.archived === false).reverse();
        // this.showToast('success', 'SUCESS', 'Data Loaded Successfuly');
    }, (err) => {
      console.log(err);
      this.showToast('danger', 'DANGER', 'Error While Retrieving Data');

    });
  }





  getFirstNameSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.firstName === event);
    }, (err) => {
      console.log(err);

    });
  }

  getLastNameSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.lastName === event);
    }, (err) => {
      console.log(err);

    });
  }


  getStatusSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.status === event).reverse();
    }, (err) => {
      console.log(err);

    });
  }

  getJobSelected(event) {
    this.as.findAll().subscribe((data: Application[]) => {
      this.applications = data.filter((e) => e.refJob === event);
    }, (err) => {
      console.log(err);

    });
  }

  archiveApplication(id: string) {
    this.as.archiveApplication(id).subscribe({
      next: (res) => {
        this.showToast('success', 'SUCCESS', 'Application Archived Successfully');
        this.router.navigate(['/hr/recruitment/history/applications']); // Redirect
      },
      error: (err) => {
        console.error(err);
        this.showToast('danger', 'ERROR', 'Failed to Archive Application');
      },
    });
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    if (!this.selectedFile) {
      this.showToast('warning', 'No File Selected', 'Please choose a file before uploading');
      return;
    }

    const fileName = this.selectedFile.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (fileExtension !== 'csv') {
      this.showToast('warning', 'Invalid File Type', 'Please upload a .csv file');
      return;
    }

    this.loading = true;
    this.as.uploadFile(this.selectedFile).subscribe({
      next: () => {
        this.showToast('success', 'SUCCESS', 'File Uploaded Successfully');
        this.pollForChanges(); // Start polling for changes
        this.getApplications();
      },
      error: (err) => {
        console.error('Error uploading file', err);
        this.showToast('danger', 'ERROR', 'Error while uploading file');
        this.loading = false;
      },
    });
  }


  pollForChanges() {
    const initialCount = this.applications.length;

    const poll$ = timer(0, this.pollingInterval).pipe(
      switchMap(() => forkJoin([this.as.findAll()])),
      finalize(() => this.loading = true),
    );

    poll$.subscribe({
      next: ([applications]) => {
        const newApplications = [...applications].filter(app => app.archived === false).reverse();
        if (newApplications.length !== initialCount) {
          this.applications = newApplications;
          this.showToast('success', 'SUCCESS', 'New data uploaded successfully');
          this.loading = false;
          this.getApplications();
        }
      },
      error: (err) => {
        setTimeout(() => {
          this.showToast('success', 'SUCCESS', 'New data uploaded successfully');
          this.loading = false;
          this.getApplications();
        }, 6000); // 5000 milliseconds = 5 seconds
      },
    });
  }
  triggerFileInput() {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  }
}
