import {Component, OnInit, ViewChild, ElementRef, Renderer2, TemplateRef} from '@angular/core';
import {Employee} from '../../../models/Employee';
import {EmployeeService} from '../../../services/employeeServices/employee.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Department} from '../../../models/Department';
import {DepartmentService} from '../../../services/departmentservices/department.service';
import {Contract} from '../../../models/Contract';
import {ContractService} from '../../../services/contractServices/contract.service';
import {MatriceServiceService} from '../../../services/matriceService/matrice-service.service';
import {
  NbComponentStatus, NbDialogService,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {Matrice} from '../../../models/Matrice';


@Component({
  selector: 'ngx-matrice-competence',
  templateUrl: './matrice-competence.component.html',
  styleUrls: ['./matrice-competence.component.scss'],
})
export class MatriceCompetenceComponent implements OnInit {
  config: NbToastrConfig;
  content = 'Operation achieved, reload your page';
  duration = 2000;
  status: NbComponentStatus = 'primary';
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

  list: Employee[] = []; // Stocke les employés
  form: FormArray;
  listD: Department[] = [];
  contracts: Contract[] = [];
  matrices: Matrice[] = []; // This will hold the list of matrices displayed on the page
  selectStyles: any[] = [];
  private location: Location;
  selectStyless: any[] = [];
  selectedModes: { [key: string]: string } = {};
  editingIndex: number | null = null; // Aucune matrice n'est en édition par défaut
  options = [
    { value: 'This is value 1', label: 'Option 1', checked: true },
    { value: 'This is value 2', label: 'Option 2' },
    { value: 'This is value 3', label: 'Option 3' },
    { value: 'This is value 4', label: 'Option 4', disabled: true },
  ];
  matriceData = {
    employee: '',
    department: '',
    contractType: '',
    niveauCollaboration: '',
    genre: '',
    age: '',
    renumeration: '',
    salaire: '',
    competence1: '',
    competence2: '',
    competence3: '',
    competence4: '',
    competence5: '',
    competence6: '',
    competence7: '',
    competence8: '',
    competence9: '',

  };


  statuses: NbComponentStatus[] = ['basic', 'primary', 'success', 'warning', 'danger', 'info', 'control'];

  formp: FormGroup;
  @ViewChild('radialProgress', { static: true }) radialProgress: ElementRef;
  @ViewChild('rangeInput', { static: true }) rangeInput: ElementRef;

  constructor(private es: EmployeeService,
              private fb: FormBuilder,
              private ds: DepartmentService,
              private cs: ContractService,
              private toastrService: NbToastrService,
              private matriceService: MatriceServiceService,
              private dialogService: NbDialogService,
              private renderer: Renderer2) {} // Injectez votre EmployeeService

  ngOnInit() {
    this.getAllEmps();
    this.getDepartments();
    this.getContracts();
    this.loadMatrices();
    this.formp = this.fb.group({
      progressInput: [0] , // Initial value set to 0
    });
  }
  loadMatrices() {
    this.matriceService.findAllMatrice().subscribe(
      (matrices) => {
        this.matrices = matrices;  // Store the fetched matrices in the component's array
      },
      (error) => {
        console.error('Failed to fetch matrices:', error);
      },
    );
  }
  getRadioGroups() {
    return new Array(4); // Adjust the number as needed
  }
  getAllEmps() {
    this.es.findAll().subscribe(
      (data: Employee[]) => {
        console.log(data);
        this.list = data;
      },
      (err) => {
        console.error('Error fetching employees:', err);
      },
    );
  }
  getDepartments() {
    this.ds.findAllDepts().subscribe(
      (data: Department[]) => { this.listD = data;  } , (err) => {
        console.log(err);
      },
    );
  }
  getContracts() {
    this.cs.findAll().subscribe(
      (data: Contract[]) => {
        this.contracts = data ;
      },
      (err) => {console.log(err); },
    );
  }
  onAddMatrice() {
    console.log(this.matriceData); // Log the data to check it's correctly bound
    this.matriceService.addMatrice(this.matriceData).subscribe(
      response => {
        console.log('Matrice added successfully!', response);
        this.showToast('success', 'COMPLETE', 'Matrice added successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      error => {
        console.error('Error adding matrice:', error);
        this.showToast('danger', 'ERROR', 'Error adding Matrice');
      },
    );
  }

  saveMatrice(index: number) {
    this.matriceService.updateMatrice(this.matrices[index].id, this.matrices[index]).subscribe(
      response => {
        console.log('Matrice updated successfully', response);
        this.editingIndex = null; // Reset l'index d'édition
      },
      error => {
        console.error('Failed to update matrice', error);
      },
    );
  }

  // Dans votre composant TypeScript
  startEditing(i: number): void {
    this.editingIndex = i;
    // Créer une copie temporaire des données de la matrice
    this.matriceData = {...this.matrices[i]};
  }

  cancelEditing(): void {
    if (this.editingIndex !== null) {
      // Restaurer les données originales
      this.matrices[this.editingIndex] = {...this.matriceData};
      this.editingIndex = null;
    }
  }

  deleteMatrice(matrice: Matrice, index: number): void {
      this.matriceService.deleteMatrice(matrice).subscribe(
        response => {
          console.log('Deletion successful', response);
          this.matrices.splice(index, 1); // Remove the matrice from the array if successfully deleted
          this.showToast('success', 'Deleted Successfully', 'The matrice has been deleted successfully.');
        },
        error => {
          console.error('Error deleting matrice', error);
          this.showToast('danger', 'Deletion Failed', 'Failed to delete matrice.');
        },
      );
    }

  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(
      dialog,
      { context: 'Are you sure to delete this item ?' });
  }

  /* toggleEdit(matrice: Matrice) {
     matrice.isEditing = !matrice.isEditing;
   }

   updateMatrice(matrice: Matrice) {
     this.matriceService.updateMatrice(matrice.id, matrice).subscribe(
       updatedMatrice => {
         console.log('Update successful', updatedMatrice);
         this.showToast('success', 'Update Successful', 'The matrice has been updated successfully.');
         matrice.isEditing = false; // Turn off editing mode after saving
       },
       error => {
         console.error('Update failed', error);
         this.showToast('danger', 'Update Failed', 'Failed to update matrice.');
       },
     );
   }*/

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body,
      ` ${titleContent}`,
      config);
  }
  onSelectionChangeNiveau(value: string, index: number) {
    const styleMap = {
      'Niveau 1': { 'background-color': '#008000', 'color': 'white' },
      'Niveau 0': { 'background-color': '#FF8C00', 'color': 'black' },
      'Niveau -1': { 'background-color': 'rgb(183,48,48)', 'color': 'black' },
    };
    this.selectStyless[index] = styleMap[value] || {};
  }

}
