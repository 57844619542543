import { Component, OnInit } from '@angular/core';
import { Contract } from '../../../models/Contract';
import { ContractService } from '../../../services/contractServices/contract.service';
import { ExportService } from '../../../../../shared/exports/export.service';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {DocumentService } from '../../../services/document.service';
@Component({
  selector: 'ngx-keypad-contracts',
  templateUrl: './keypad-contracts.component.html',
  styleUrls: ['./keypad-contracts.component.scss'],
})
export class KeypadContractsComponent implements OnInit {

  contracts: Contract[] = [];
  search: string = '';
  pageSize: number = 9;
  current: string | number;
  total: string | number;
  loading = false;

  constructor(private cs: ContractService, private exportService: ExportService,private documentService : DocumentService) { }

  ngOnInit(): void {
    this.loading = true; // Activate loading indicator at the start
    forkJoin({
      contracts: this.cs.findAll(),  // should return Observable<Contract[]>
    }).pipe(
      finalize(() => this.loading = false),  // Deactivate loading indicator when all requests are done
    ).subscribe({
      next: (results) => {
        this.contracts = results.contracts;
      },
      error: (error) => {
        console.error('Error loading data', error);
      },
    });
  }

  getContracts() {
    this.cs.findAll().subscribe(
      (data: Contract[]) => {this.contracts = data; console.log(data);  },
      (err) => {console.log(err); },
    );
  }

  getContractsAscHiringDate() {
    this.cs.findAllAscHiringDate().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsDescHiringDate() {
    this.cs.findAllDescHiringDate().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsAscEndDate() {
    this.cs.findAllAscEndDate().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsDescEndDate() {
    this.cs.findAllDescEndDate().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsAscOfficialSignature() {
    this.cs.findAllAscOfficialSignature().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsDescOfficialSignature() {
    this.cs.findAllDescOfficialSignature().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsAscGrossAnnualSalary() {
    this.cs.findAllAscGrossAnnualSalary().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  getContractsDescGrossAnnualSalary() {
    this.cs.findAllDescGrossAnnualSalary().subscribe(
      (data: Contract[]) => {this.contracts = data;  },
      (err) => {console.log(err); },
    );
  }

  filtreByContratType(e, x) {
    this.cs.findAll().subscribe(
      (data: Contract[]) => {
        this.contracts = [];
        this.contracts = data.filter(
          (contr =>
              x === 'contractType' ? contr.contractType === e : contr.contractType === e
          ),
        );
      }, (err) => {
        return err;
      });
  }

  filtreByStatus(e, x) {
    this.cs.findAll().subscribe(
      (data: Contract[]) => {
        this.contracts = [];
        this.contracts = data.filter(
          (contr =>
              x === 'status' ? contr.status === e : contr.status === e
          ),
        );
      }, (err) => {
        return err;
      });
  }

  filtreByHoursPlan(e, x) {
    this.cs.findAll().subscribe(
      (data: Contract[]) => {
        this.contracts = [];
        this.contracts = data.filter(
          (contr =>
              x === 'hourlyDistribution' ? contr.hourlyDistribution === e : contr.hourlyDistribution === e
          ),
        );
      }, (err) => {
        return err;
      });
  }

  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.contracts, 'dataContracts');
    const trace = {
      fileName: 'Contracts_Lists.xlsx',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      module: 'HCM',
      createdAt: new Date().toISOString()
    };

    this.documentService.saveTrace(trace).subscribe({
      next: () => console.log('Trace saved successfully'),
      error: (err) => console.error('Error saving trace', err)
    });
  }




}
