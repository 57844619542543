<div class="container bootdey" *ngIf="pay">
  <div class="row invoice row-printable">
    <div class="col-md-12">
      <!-- col-lg-12 start here -->
      <div class="panel panel-default plain" id="dash_0">
        <!-- Start .panel -->
        <div class="panel-body p30">
          <div class="row align-items-center">
            <div class="col">
              <h3>Pay Sheet Employee</h3>
            </div>
            <div class="col text-right">
              <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic"
                 [routerLink]="['/hr/pay/grid4payroll']" nbButton>
                <nb-icon icon="corner-up-right-outline"></nb-icon>
              </a>
            </div>
          </div>

          <div class="row mt-2">

            <!-- Start .row -->
            <div class="col-lg-6">
              <nb-card>
                <nb-card-body>
                  <p class="mx-2 mt-2">Company Name :  Ipact Consult</p>
                  <br>
                  <p class="mx-2 mt-2">Company Address : ipactconsult@gmail.com</p>
                  <br>
                  <p class="mx-2 mt-2">N CNSS : 00112233IC</p>
                </nb-card-body>

              </nb-card>
            </div>
            <div class="col-lg-6">
              <nb-card>
                <nb-card-body>
                  <p class="text-center"> Pay Sheet </p> <br>
                  <p>Matriculate N CNSS/CNRPS : {{pay?.contract?.employee?.employeeReference}}</p>
                  <p>Marital Status : {{pay?.contract?.employee?.employeeMaritalStatus}}</p>
                  <p>Number of kids : {{pay?.contract?.employee?.employeeNbKids}}</p>
                </nb-card-body>
              </nb-card>
            </div>
            <!-- col-lg-6 end here -->
            <div class="col-lg-12">
              <div class="row mt25">
                <div class="col-lg-6">
                  <nb-card>
                    <nb-card-body>
                        <p>Position : {{pay?.contract?.employee?.roleEmployee}}</p>
                        <p>SubDepartment  : {{pay?.contract?.employee?.subDepartment?.subDepartmentName}}</p>
                    </nb-card-body>
                  </nb-card>
                </div>
                <div class="col-lg-6">
                  <nb-card>
                    <nb-card-body>
                      <p>Employee Name : {{pay?.contract?.employee?.employeeName}}</p>
                      <p>Employee Email : {{pay?.contract?.employee?.employeeEmail}}</p>
                    </nb-card-body>
                  </nb-card>
                </div>
              </div>
              <div class="invoice-items row">
                <div class="table-responsive col-12" style="overflow: hidden; outline: none;" tabindex="0">
                  <table class="table table-striped w-100">
                    <thead>
                    <tr>
                      <th class="per5"><p>N</p></th>
                      <th class="per70 "><p>Payroll element</p></th>
                      <th class="per70 "><p>Percentage</p></th>
                      <th class="per5 text-center"><p>Amount</p></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class=""><p>10</p></td>
                      <td><p>Base Salary</p></td>
                      <td></td>
                      <td class="text-center"><p>{{pay.baseSalary}} {{"DT"}}</p></td>
                    </tr>
                    <tr>
                      <td class=""><p>100</p></td>
                      <td><p>Conventional Bonus</p></td>
                      <td></td>
                      <td class="text-center"><p>{{pay.primeConventionnelle}} {{"DT"}}</p></td>
                    </tr>
                    <tr>
                      <td class=""><p>150</p></td>
                      <td><p>Nun-Conventional Bonus</p></td>
                      <td></td>
                      <td class="text-center"><p>{{pay.primeNonConventionnelle}} {{"DT"}}</p></td>
                    </tr>
                    <tr>
                      <td class=""><p></p></td>
                      <td><p>Total Gross</p></td>
                      <td></td>
                      <td class="text-center"><p> {{pay.gain}} {{"DT"}}</p></td>
                    </tr>
                    <tr>
                      <td class=""><p>200</p></td>
                      <td><p>CNSS</p></td>
                      <td><p>9,18%</p></td>
                      <td class="text-center"><p> {{pay.cnssAnnuel/12}} {{"DT"}}</p></td>
                    </tr>
                    <tr>
                      <td class=""><p>600</p></td>
                      <td><p>Net Imposed</p></td>
                      <td></td>
                      <td class="text-center"><p> {{pay.netSalaryImposed/12 - 200000}} {{"DT"}}</p></td>
                    </tr>

                    <tr>
                      <td class=""><p>700</p></td>
                      <td><p>IRPP</p></td>
                      <td></td>
                      <td class="text-center"><p> {{pay.irpp}} {{"DT"}}</p></td>
                    </tr>

                    <tr>
                      <td class=""><p>800</p></td>
                      <td><p>Net Salary</p></td>
                      <td></td>
                      <td class="text-center"><p> {{pay.netSalary - pay.cnam}} {{"DT"}}</p></td>
                    </tr>
                    <tr>
                      <td class=""><p>900</p></td>
                      <td><p>CNAM</p></td>
                      <td></td>
                      <td class="text-center"><p> {{pay.cnam}} {{"DT"}}</p></td>
                    </tr>

                    </tbody>
                    <tfoot style="border: 1px solid white">

                    <tr>
                      <th></th>
                      <th colspan="2" class="text-right"><p>Net to pay:</p></th>
                      <th class="text-center"><p>{{pay.netSalary}}{{"DT"}}</p></th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <!-- col-lg-12 end here -->
          </div>
          <!-- End .row -->
        </div>
      </div>
      <!-- End .panel -->
    </div>
    <!-- col-lg-12 end here -->
  </div>
</div>
