<div class="container" *ngIf="employee" id="htmlData">
    <div class="row">
        <div class="col-md-5 col-sm-12">
            <h3>
                Employee Details  </h3>
        </div>

      <div class="col-md-2 col-sm-8"></div>
      <div class="col-md-5" align="right">
        <!--<a class="mx-3" status="primary" nbTooltip="PaySheet" nbTooltipStatus="primary" shape="round" [routerLink]="['/hr/pay/paysheet/details/',employee.id]" nbButton>
          <nb-icon icon="file-text-outline"></nb-icon>
        </a>-->

        <button  shape="round" nbTooltip="Print file" class="rounded-circle" nbTooltipStatus="primary"  nbButton status="danger"
           printTitle="Employee Details"
           printSectionId="print-section"
           ngxPrint
           [useExistingCss]="true"
        >
          <nb-icon icon="printer-outline"></nb-icon>
        </button>
       &nbsp;
        <a status="warning" nbTooltip="Edit" nbTooltipStatus="primary" shape="round" [routerLink]="['/hr/employee/edit/',employee.id]" nbButton>
          <nb-icon icon="edit-outline"></nb-icon> </a>
        &nbsp;
        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic"    [routerLink]="['/hr/employee/list_']" nbButton>
          <nb-icon icon="corner-up-right-outline"></nb-icon>
        </a>
      </div>

    </div>
  <div class="container" id="print-section">
          <div class="row mt-3">
            <div class="col-md-4 mb-3 ">
              <nb-card>
                <nb-card-body class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                   <div class="row">

                      <img style="width: 150px;height: 150px;border-radius: 100%;"  src="{{employee.employeeProfileImage}}" alt="">
                   </div>

                    <div class="mt-3">
                      <h6>{{employee.employeeName}}</h6>
                      <div class="row mt-3">
                        <div class="col-md-12">

                                <p class="small">
                                   Position :
                               &nbsp;&nbsp;{{employee.roleEmployee}}
                                </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </nb-card-body>
            </nb-card>
            <nb-card class="">
                <div class="col-sm-6">

                      <div class="card-body">
                        <h6 class="d-flex align-items-center mb-3">Contract</h6>
                        <span *ngFor="let contract of contracts">
                          <span class="row" *ngIf="employee.id === contract?.employee?.id">
                              <small class="col-12 w-100">Type : {{contract.contractType}}</small>  <br>
                              <small class="col-12 w-100">Expiration : {{contract.endDate | date : "EEEE dd MMM YYYY"}}</small> <br>
                              <small class="col-12 w-100">Gross Annual Salary : {{contract.grossAnnualSalary}}</small>
                          </span>
                        </span>


                      </div>

                  </div>

            </nb-card>
            <nb-card class="">
                <div class="col-sm-6">

                      <div class="card-body">
                        <h6 class="d-flex align-items-center mb-3">Expenses</h6>
                        <span *ngFor="let expense of expenses">
                          <span *ngIf="employee.id === expense?.employee?.id">
                             <small>Name : {{expense.expenseName}}</small>  <br>
                             <small>Type : {{expense.expenseType}}</small>  <br>
                             <small>Date : {{expense.expenseDate | date : "EEEE dd MMM YYYY"}}</small>
                          </span>
                        </span>



                      </div>

                  </div>

            </nb-card>






            </div>
            <div class="col-md-8">
            <nb-accordion>
              <nb-accordion-item expanded="true">
              <nb-accordion-item-header>Personal Information</nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Reference</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeReference}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Full Name</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeName}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Born on</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeDateOfBirth | date: 'YYYY MMM dd'}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Nationality</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeNationality }}
                  </div>
                </div>
                <hr>

              </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>  <br>
            <nb-accordion>
              <nb-accordion-item>
              <nb-accordion-item-header>Contact Information</nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Email Address</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeEmail}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Cell Phone</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeCellPhone }}
                  </div>
                </div>
                <hr>
              </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion> <br>
            <nb-accordion>
              <nb-accordion-item>
              <nb-accordion-item-header>SubDepartment Assigned</nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">SubDepartment Name</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee?.subDepartment?.subDepartmentName}}
                    <span *ngIf="employee.subDepartment?.subDepartmentName === null">
                      This employee is not assigned yet.
                    </span>
                  </div>
                </div>
                <hr>
              </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>  <br>
            <nb-accordion>
              <nb-accordion-item>
              <nb-accordion-item-header>Social Security Information</nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Social Security</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeSocialSecurity}}
                    <span *ngIf="employee.employeeSocialSecurity === null">
                      This field is not fill it yet.
                    </span>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Marital Status</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeMaritalStatus}}
                    <span *ngIf="employee.employeeMaritalStatus === null">
                      This field is not fill it yet.
                    </span>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0 text-sm">Number of Kids</p>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{employee.employeeNbKids}}
                    <span *ngIf="employee.employeeNbKids === 0">
                      This employee does not have any kids.
                    </span>
                  </div>
                </div>
                <hr>
              </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion> <br>
            <div class="row gutters-sm">
                <div class="col-sm-6 mb-3">
                  <nb-card class="h-100">
                    <div class="card-body">
                      <h6 class="d-flex align-items-center mb-3">Skills</h6>
                      <span *ngFor="let skill of skills">
                        <span *ngIf="employee.id === skill?.employee?.id">
                             <small>{{skill.skillName}}</small>
                             <nb-progress-bar nbTooltip="score: {{skill.score}}" status="primary" aria-valuemax="10" [displayValue]="true" [value]="skill.score" size="tiny"></nb-progress-bar>
                        </span>
                      </span>

                    </div>
                </nb-card>
                </div>
                <div class="col-sm-6 mb-3">
                  <nb-card class="h-100">
                    <div class="card-body">
                      <h6 class="d-flex align-items-center mb-3">Evaluation</h6>
                      <span *ngFor="let ev of evaluation">
                        <span class="row" *ngIf="employee.id === ev?.employee?.id">
                            <small class="w-100 col-lg-12">Limit Date : {{ev.limitDate | date : 'EEEE dd MMM YYYY'}}</small> <br>
                            <small class="w-100 col-lg-12">Type : {{ev.evaluationType}}</small><br>
                            <small  class="w-100 col-lg-12">Attachment :
                                 <a  nbButton size="tiny" shape="round" status="danger" href="{{ev.attachmentEvaluation}}" target="_blank">
                                    <nb-icon icon="printer-outline"></nb-icon> View
                                 </a>
                            </small><br>

                        </span>
                        <hr>
                      </span>

                    </div>
                  </nb-card>
                </div>
              </div>



            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-6">
                <h6 class="d-flex align-items-center mb-3">Absences</h6>
              <nb-card size="small">

                <nb-tabset>
                  <nb-tab tabTitle="New Request">
                    <form class="step-container" [formGroup]="leave_request_form">
                      <h6>Send Leave Request</h6>
                      <nb-stepper #stepper class="mt-1">
                          <nb-step [stepControl]="leave_request_form" label="Motif">
                              <label for="">Motif</label>
                              <nb-select
                              [(ngModel)]="leaveRequest.motif"
                              formControlName="motif"
                              name="motif"
                              id="motif"
                              fullWidth size="medium"
                              placeholder="select Motif">
                                  <nb-option value="Absence To Justify">Absence To Justify</nb-option>
                                  <nb-option value="RTT Day">RTT Day</nb-option>
                                  <nb-option value="Unpaid Leave">Unpaid Leave</nb-option>
                                  <nb-option value="Legal Paid Leave">Legal Paid Leave</nb-option>
                              </nb-select>
                          </nb-step>

                          <nb-step [stepControl]="leave_request_form" label="Period">
                              <div class="row">
                                <div class="col-md-6">
                                  <label for="">Start Date</label>
                                  <input
                                  [nbDatepicker]="dateTimePicker" fieldSize="medium" formControlName="startDate"
                                  fullWidth id="startDate" name="startDate" [(ngModel)]="leaveRequest.startDate" nbInput placeholder="Pick Date">
                                  <nb-datepicker #dateTimePicker></nb-datepicker>

                                </div>
                                <div class="col-md-6">
                                  <label for="">End Date</label>
                                  <input
                                  [nbDatepicker]="dateTimePicker1" fieldSize="medium" formControlName="endDate"
                                  fullWidth id="endDate" name="endDate" [(ngModel)]="leaveRequest.endDate" nbInput placeholder="Pick Date">
                                  <nb-datepicker #dateTimePicker1></nb-datepicker>

                                </div>
                                <div class="col-md-6">
                                  <label for="">Start</label>
                                  <nb-select
                                  [(ngModel)]="leaveRequest.start"
                                  formControlName="start"
                                  name="start"
                                  id="start"
                                  fullWidth size="medium"
                                  placeholder="select start">
                                      <nb-option value="In The Morning">In The Morning</nb-option>
                                      <nb-option value="Afternoon">Afternoon</nb-option>
                                  </nb-select>

                                </div>
                                <div class="col-md-6">
                                  <label for="">End</label>
                                  <nb-select
                                  [(ngModel)]="leaveRequest.end"
                                  formControlName="end"
                                  name="end"
                                  id="end"
                                  fullWidth size="medium"
                                  placeholder="select End">
                                  <nb-option value="In The Morning">In The Morning</nb-option>
                                  <nb-option value="Afternoon">Afternoon</nb-option>
                                  </nb-select>

                                </div>


                              </div>
                          </nb-step>

                          <nb-step [stepControl]="leave_request_form" label="Comments">
                              <div class="row">
                                <div class="col-md-12">
                                  <label for="">Comments</label>
                                </div>
                                <div class="col-md-12">

                                  <textarea [(ngModel)]="leaveRequest.comments" formControlName="comments" name="comments" nbInput id="comments" fullWidth rows="2"></textarea>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-5" align="left">
                                  <nb-toggle (change)="draftMode(); clicked = true" class="mt-2" status="basic">Draft Mode</nb-toggle>
                                </div>
                                <div class="col-md-3"></div>
                                <div class="col-md-4" align="right">
                                  <button  (click)="createRequest(); clicked = true" nbButton nbStepperNext
                                  type="submit" status="success"> Confirm
                                  </button>

                                </div>

                              </div>


                          </nb-step>


                      </nb-stepper>
                  </form>
                  </nb-tab>
                  <nb-tab tabTitle="List requests">
                    <h6>Requests</h6>
                    <div class="row mx-1">
                      <div class="row col-lg-12" *ngIf="!leaveRequests || leaveRequests?.length === 0">
                        <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
                      </div>
                                <nb-card class="card col-lg-5 mx-3" *ngFor="let request of leaveRequests | paginate : {itemsPerPage: 2, currentPage: current,totalItems: leaveRequests?.length}">

                                  <div align="right" *ngIf="request.requestStatus === 'Validate' ">
                                    <nb-badge status="success" text={{request.requestStatus}}></nb-badge>
                                  </div>
                                  <div align="right" *ngIf="request.requestStatus === 'Rejected' ">
                                    <nb-badge status="danger" text={{request.requestStatus}}></nb-badge>
                                  </div>
                                  <div align="right" *ngIf="request.requestStatus === 'Await For Validation' ">
                                    <nb-badge status="warning" text={{request.requestStatus}}></nb-badge>
                                  </div>

                                  <p class="mt-3 mx-2">
                                    <span class="label-color">Motif : </span>
                                    <span class="content-color">{{request.motif}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Start At : </span>
                                    <span class="content-color">{{request.startDate | date: 'yyyy MMM dd'}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">End At : </span>
                                    <span class="content-color">{{request.endDate | date: 'yyyy MMM dd'}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Duration : </span>
                                    <span class="content-color">{{request.duration}}</span>
                                  </p>

                                </nb-card>
                              <div style="margin-top: -25px;" class="mr-auto ml-auto" align="center">
                                <nb-card class="card" style="height: 35px; border-radius: 25px;">
                                  <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
                                </nb-card>
                              </div>

                    </div>
                  </nb-tab>
                  <nb-tab tabTitle="Draft Requests">
                    <h6>Drafts</h6>
                    <div class="row mx-1">
                      <div class="row col-lg-12" *ngIf="!leavesDrafts || leavesDrafts?.length === 0">
                        <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
                      </div>
                                <nb-card class="card col-lg-5 mx-3" *ngFor="let request of leavesDrafts | paginate : {itemsPerPage: 2, currentPage: currentD,totalItems: leavesDrafts?.length}">


                                  <div align="right" *ngIf="request.requestStatus === 'Draft' ">
                                    <nb-badge status="basic" text={{request.requestStatus}}></nb-badge>
                                  </div>
                                  <p class="mt-3 mx-2">
                                    <span class="label-color">Motif : </span>
                                    <span class="content-color">{{request.motif}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Start At : </span>
                                    <span class="content-color">{{request.startDate | date: 'yyyy MMM dd'}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">End At : </span>
                                    <span class="content-color">{{request.endDate | date: 'yyyy MMM dd'}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Duration : </span>
                                    <span class="content-color">{{request.duration}}</span>
                                  </p>
                                    <div class="mb-1" align="center">
                                      <button  nbButton shape="round" size="small" status="primary" (click)="updateRequest(request.id)">
                                        <nb-icon icon="navigation-2-outline"></nb-icon> Send
                                     </button>
                                    </div>
                                </nb-card>
                              <div [hidden]="leavesDrafts?.length === 0" style="margin-top: -25px;" class="mr-auto ml-auto" align="center">
                                <nb-card class="card" style="height: 35px; border-radius: 25px;">
                                  <pagination-controls class="pt-1 text-center" (pageChange)="currentD= $event"></pagination-controls>
                                </nb-card>
                              </div>

                    </div>
                  </nb-tab>
                </nb-tabset>
              </nb-card>
            </div>

            <div class="col-md-12 col-lg-6">
              <h6 class="d-flex align-items-center mb-3">Releases</h6>
            <nb-card size="small">

              <nb-tabset class="col-md-12">
                <nb-tab class="col-md-12 col-lg-12" tabTitle="New Release">
                  <form class="step-container" [formGroup]="release_request_form">
                    <h6>Send Release</h6>
                    <nb-stepper #stepper class="mt-1">
                        <nb-step  [stepControl]="release_request_form" label="Motif">
                            <label for="">Motif</label>
                            <nb-select
                            [(ngModel)]="release.motifRelease"
                            formControlName="motifRelease"
                            name="motifRelease"
                            id="motifRelease"
                            fullWidth size="medium"
                            placeholder="select Motif">
                                <nb-option value="Motif#1">Motif#1</nb-option>
                                <nb-option value="Motif#2">Motif#2</nb-option>
                                <nb-option value="Motif#3">Motif#3</nb-option>
                                <nb-option value="Motif#4">Motif#4</nb-option>
                            </nb-select>
                        </nb-step>

                        <nb-step [stepControl]="release_request_form" label="Period">
                            <div class="row">
                              <div class="col-md-6">
                                <label for="">Start Time</label>
                                <input
                                type="time"
                                 fieldSize="medium" formControlName="startTime"
                                fullWidth id="startTime" name="startTime" [(ngModel)]="release.startTime" nbInput placeholder="Pick Time">


                              </div>
                              <div class="col-md-6">
                                <label for="">End Time</label>
                                <input
                                type="time" fieldSize="medium" formControlName="endTime"
                                fullWidth id="endTime" name="endTime" [(ngModel)]="release.endTime" nbInput placeholder="Pick Time">

                              </div>
                            </div>
                        </nb-step>

                        <nb-step [stepControl]="release_request_form" label="Comments">
                            <div class="row">
                              <div class="col-md-12">
                                <label for="">Comments</label>
                              </div>
                              <div class="col-md-12">

                                <textarea [(ngModel)]="release.commentsRelease" formControlName="commentsRelease" name="commentsRelease" nbInput id="commentsRelease" fullWidth rows="2"></textarea>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-5" align="left">
                                <nb-toggle [disabled]="clicked" (change)="createReleaseDraft(); clicked = true" class="mt-2" status="basic">Draft Mode</nb-toggle>
                              </div>
                              <div class="col-md-3"></div>
                              <div class="col-md-4" align="right">
                                <button  (click)="createRelease()"  nbButton nbStepperNext
                                type="submit" status="success">
                                 <nb-icon icon="checkmark-circle-outline"></nb-icon> Confirm
                                </button>

                              </div>
                            </div>


                        </nb-step>


                    </nb-stepper>
                </form>

                </nb-tab>
                <nb-tab  class="col-md-12 col-lg-12" tabTitle="List Releases">
                  <h6>Releases</h6>

                     <div class="row">
                      <div class="row col-lg-12" *ngIf="!releases || releases?.length === 0">
                        <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
                      </div>
                      <div class="col-md-6" *ngFor="let request of releases | paginate : {itemsPerPage: pageSizeRelease, currentPage: currentRelease,totalItems: releases?.length}">

                                <nb-card class="card">
                                  <div align="right" *ngIf="request.releaseStatus === 'Validate' ">
                                    <nb-badge status="success" text={{request.releaseStatus}}></nb-badge>
                                  </div>
                                  <div align="right" *ngIf="request.releaseStatus === 'Rejected' ">
                                    <nb-badge status="danger" text={{request.releaseStatus}}></nb-badge>
                                  </div>
                                  <div align="right" *ngIf="request.releaseStatus === 'Pending' ">
                                    <nb-badge status="warning" text={{request.releaseStatus}}></nb-badge>
                                  </div>

                                    <p class="mt-2 mx-2">
                                      <span class="label-color">Motif : </span>
                                      <span class="content-color">{{request.motifRelease}}</span>
                                    </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Start Time : </span>
                                    <span class="content-color">{{request.startTime}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">End Time : </span>
                                    <span class="content-color">{{request.endTime}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Current : </span>
                                    <span class="content-color">{{request.createdAt | date : 'YYYY MMM dd'}}</span>
                                  </p>

                                </nb-card>

                      </div>
                      <div [hidden]="releases?.length === 0" style="margin-top: -25px;" class="mr-auto ml-auto" align="center">
                        <nb-card class="card" style="height: 35px; border-radius: 25px;">
                          <pagination-controls class="pt-1 text-center" (pageChange)="currentRelease= $event"></pagination-controls>
                        </nb-card>
                      </div>
                    </div>
                </nb-tab>

                <nb-tab  class="col-md-12 col-lg-12" tabTitle="Draft Releases">
                  <h6>Draft Releases</h6>

                     <div class="row">
                      <div class="row col-lg-12" *ngIf="!draftsReleases || draftsReleases?.length === 0">
                        <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
                      </div>
                      <div class="col-md-6" *ngFor="let request of draftsReleases | paginate : {itemsPerPage: pageSizeReleaseDraft, currentPage: currentReleaseDraft,totalItems: draftsReleases?.length}">

                                <nb-card class="card">
                                    <div align="right">
                                      <nb-badge text={{request.releaseStatus}}></nb-badge>
                                    </div>
                                  <p class="mt-2 mx-2">
                                    <span class="label-color">Motif : </span>
                                    <span class="content-color">{{request.motifRelease}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Start Time : </span>
                                    <span class="content-color">{{request.startTime}}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">End Time : </span>
                                    <span class="content-color">{{request.endTime }}</span>
                                  </p>
                                  <p class="mt-1 mx-2">
                                    <span class="label-color">Current : </span>
                                    <span class="content-color">{{request.createdAt | date : 'YYYY MMM dd'}}</span>
                                  </p>
                                  <div class="mb-1" align="center">
                                    <button  nbButton shape="round" size="small" status="primary" (click)="updateRelease(request.id)">
                                      <nb-icon icon="navigation-2-outline"></nb-icon> Send
                                    </button>
                                  </div>
                                </nb-card>

                      </div>
                      <div [hidden]="draftsReleases?.length === 0" style="margin-top: -25px;" class="mr-auto ml-auto" align="center">
                        <nb-card class="card" style="height: 35px; border-radius: 25px;">
                          <pagination-controls class="pt-1 text-center" (pageChange)="currentReleaseDraft= $event"></pagination-controls>
                        </nb-card>
                      </div>
                    </div>
                </nb-tab>

              </nb-tabset>
            </nb-card>
          </div>

            <div class="col-md-12 col-lg-6">
                <h6 class="d-flex align-items-center mb-3">Engagement</h6>

              <nb-card >



                      Engagement
                <ngx-charts-advanced-pie-chart


                [scheme]="colorScheme"
                [results]="single">
              </ngx-charts-advanced-pie-chart>



              </nb-card>
            </div>

            <div class="col-6">
                <h6 class="d-flex align-items-center mb-3">Remuneration</h6>

                <nb-card size="small">
                  <nb-tabset fullWidth>
                    <nb-tab tabTitle="Item #1">
                        <span>Content #1</span>

                    </nb-tab>
                    <nb-tab tabTitle="Item #2">
                      <span>Content #2</span>
                    </nb-tab>
                    <nb-tab tabTitle="Item #3">
                      <span>Content #3</span>
                    </nb-tab>
                  </nb-tabset>
                </nb-card>
              </div>



          </div>
  </div>

    </div>
